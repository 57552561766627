import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Navbar,
  Icon,
  Hero,
  Footer,
  Container,
  Content
} from 'react-bulma-components'
import { GitHub, Twitter } from 'react-feather'
import cx from 'classnames'

import './styles/base.scss'

const isActive = (pathname = '', validPathnames = []) =>
  cx({ 'is-active': validPathnames.includes(pathname) })

class IndexLayout extends React.Component {
  // eslint-disable-next-line
  state = {
    open: false
  }
  render() {
    const {
      location: { pathname },
      children
    } = this.props

    const navbarProps = {}
    if (this.props.navbarIsFixed) {
      navbarProps.fixed = 'top'
      navbarProps.style = { backgroundColor: '#FFF' }
    }

    const navbar = (
      <>
        <Navbar.Brand>
          <Navbar.Item
            renderAs="span"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <Link to="/">
              <h1 className="teologovLogo">
                {`{ Teologov`}
                <span className="redColonDarken"> :</span>
                {`}`}
              </h1>
            </Link>
          </Navbar.Item>
          <Navbar.Burger
            active={this.state.open}
            onClick={() => {
              this.setState({
                open: !this.state.open
              })
            }}
          />
        </Navbar.Brand>
        <Navbar.Menu active={this.state.open} className="centered">
          <Navbar.Container>
            <Navbar.Item
              href="https://github.com/teologov"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon>
                <GitHub />
              </Icon>
            </Navbar.Item>
            <Navbar.Item
              href="https://twitter.com/ateologov"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon>
                <Twitter color="#1da1f2" />
              </Icon>
            </Navbar.Item>
          </Navbar.Container>
          <Navbar.Container position="end">
            <Link
              to="/"
              className={[isActive(pathname, ['/']), 'navbar-item'].join(' ')}
            >
              Home
            </Link>
            <Link
              to="/apps"
              className={[
                isActive(pathname, [
                  '/apps',
                  '/apps/',
                  '/apps/russian-guru',
                  '/apps/russian-guru/',
                  '/apps/eye-twenty',
                  '/apps/eye-twenty/'
                ]),
                'navbar-item'
              ].join(' ')}
            >
              Apps
            </Link>
            <Navbar.Item href="/blog/">Blog</Navbar.Item>
          </Navbar.Container>
        </Navbar.Menu>
      </>
    )
    const menu = (
      <Hero.Head renderAs="header">
        <header className="nav">
          <div className="container">
            <Navbar active={this.state.open} {...navbarProps}>
              {this.props.navbarIsFixed ? (
                <Container>{navbar}</Container>
              ) : (
                <>{navbar}</>
              )}
            </Navbar>
          </div>
        </header>
      </Hero.Head>
    )
    let body

    if (this.props.bodyInContainer) {
      body = (
        <Container style={{ alignSelf: this.props.alignBody }}>
          <Content style={{ textAlign: 'center', flex: 1 }}>{children}</Content>
        </Container>
      )
    } else {
      body = (
        <Content
          style={{
            textAlign: 'center',
            flex: 1,
            alignSelf: this.props.alignBody
          }}
        >
          {children}
        </Content>
      )
    }

    return (
      <Hero size="fullheight">
        <div id="parts" />
        {menu}
        <Hero.Body className="is-paddingless">{body}</Hero.Body>
        <Hero.Footer>
          <Footer>
            <Container>
              <Content style={{ textAlign: 'center' }}>
                <p style={{ marginBottom: 0 }}>
                  © {new Date().getFullYear()}, Andrey Teologov
                </p>
              </Content>
            </Container>
          </Footer>
        </Hero.Footer>
      </Hero>
    )
  }
}

IndexLayout.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.node,
  alignBody: PropTypes.string,
  bodyInContainer: PropTypes.bool,
  navbarIsFixed: PropTypes.bool
}

IndexLayout.defaultProps = {
  alignBody: 'middle',
  bodyInContainer: true,
  navbarIsFixed: false
}

export default IndexLayout
