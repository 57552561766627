module.exports = {
  title: `Indie Maker and Engineer`,
  blogTitle: `The Nomad's Cruise`,
  author: `Andrey Teologov`,
  description: `Andrey Teologov's Home Page`,
  // is used in rss
  blogDescription: `Andrey Teologov's blog. Life, engineering, product development, music`,
  siteUrl: `https://teologov.com`,
  social: {
    twitter: `ateologov`
  },
  // will do via withPrefix() to load it
  logo: 'teologov-logo.png'
}
